/* eslint-disable @next/next/no-img-element */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import { BackgroundImageSection } from '@/containers-m/BackgroundImageSection';
import { HubspotFormSimplified } from '@/containers-m/HubspotFormSimplified';

//data
import { defaultOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import { Hero } from './containers/';

//styles
import {
  PageWrapper,
  AlignedSection,
  AlignedSectionNoMargin,
  Title,
  CardGroup,
  Card,
  Description,
  Text,
  ContainerWithBorder,
  CardSideBySide,
  Picture,
  ButtonWrapper,
} from './Contact.styles';
import { Container } from '@/components/Grid/Container';

//assets
import { OFFICES } from '@/datafiles/offices/address';
import { MirandaHollowSecondaryButton } from '@/components/Button';

const bgSection = {
  title: 'Become a Loadie!',
  description: (
    <>
      We’re always looking to connect with world-class thinkers, strategists,
      doers, and leaders. Check out our open positions below.
    </>
  ),
  image: {
    xxlarge: 'images/careers/become/become-loadie-xxlg.jpg',
    large: 'images/careers/become/become-loadie-lg.jpg',
    medium: 'images/careers/become/become-loadie-md.jpg',
    small: 'images/careers/become/become-loadie-sm.jpg',
  },
  CTA: {
    label: 'Learn more',
    link: 'https://jobs.lever.co/loadsmart/',
    external: true,
  },
  biggerBg: false,
};

const form_content = {
  title: <>Get In Touch</>,
  subtitle: (
    <>
      Loadsmart is your next-gen logistics solutions provider that supports your
      team from the time an order is placed to the final invoice of shipment.
      Drop us a line and we’ll be in touch with you soon.
    </>
  ),
};

const Contact = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <Hero />
          <AlignedSection>
            <Container>
              <Title>Our office</Title>
              <CardGroup>
                <Card>
                  <Description>{OFFICES.main.label}</Description>
                  <Text> {OFFICES.main.address}</Text>
                  <Text>
                    {`${OFFICES.main.city}, ${OFFICES.main.state}. ${OFFICES.main.zipcode}`}
                  </Text>
                  <ButtonWrapper>
                    <MirandaHollowSecondaryButton
                      as="a"
                      href="https://maps.app.goo.gl/3yfHgLNzXQ8cu9PR6"
                      target={`_blank`}
                      rel={`noreferrer noopener`}
                    >
                      <span>Get Directions</span>
                    </MirandaHollowSecondaryButton>
                  </ButtonWrapper>
                </Card>
                <Card>
                  <Picture>
                    <source
                      srcSet={`/images/contact/map-xxlg.jpg`}
                      media="(min-width: 1920px)"
                    />
                    <source
                      srcSet={`/images/contact/map-lg.jpg`}
                      media="(min-width: 1024px)"
                    />
                    <source
                      srcSet={`/images/contact/map-md.jpg`}
                      media="(min-width: 768px)"
                    />
                    <img
                      src={`/images/contact/map-sm.jpg`}
                      alt="headquarter map"
                      width="392"
                      height="260"
                    />
                  </Picture>
                </Card>
              </CardGroup>
            </Container>
          </AlignedSection>
          <AlignedSectionNoMargin>
            <ContainerWithBorder>
              <Title>Need assistance?</Title>
              <CardGroup>
                <CardSideBySide>
                  <Description>Carrier Sales</Description>
                  <Text>carrier@loadsmart.com</Text>
                  <Text>(646) 887 6278</Text>
                </CardSideBySide>
                <CardSideBySide>
                  <Description>Shipper Sales</Description>
                  <Text>sales@loadsmart.com</Text>
                  <Text>(646) 887 6278</Text>
                </CardSideBySide>
              </CardGroup>
            </ContainerWithBorder>
          </AlignedSectionNoMargin>

          <div id="contact-form">
            <HubspotFormSimplified
              content={form_content}
              {...defaultOptions}
              disclaimer={true}
              theme="dark"
            />
          </div>
        </>
        <BackgroundImageSection content={bgSection} />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Contact;
export { Contact };
