import styled from 'styled-components';
import { Section, Container } from '@/components/Grid';
import { screen } from '@/helpers/screen';
import { FlexColumn, FlexRow } from '@/components/Grid/Flex';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const PageWrapper = styled.div`
  background: rgb(${getToken('color-background-primary')});
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const AlignedSection = styled(Section)`
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const AlignedSectionNoMargin = styled(AlignedSection)`
  margin-top: 0;
`;

export const ContainerWithBorder = styled(Container)`
  padding-top: 50px;

  border-top: 1px solid #d3d9d9;

  ${screen.md} {
    padding-top: 60px;
  }

  ${screen.lg} {
    padding-top: 80px;
  }

  ${screen.xxlg} {
    padding-top: 100px;
  }
`;

export const Title = styled.h2`
  font-family: ${getToken('platform-font-title')};
  font-weight: ${getToken('font-weight-bold')};
  font-size: 38px;
  line-height: 47.5px;

  color: rgb(${getToken('color-text-primary')});

  margin-top: 0px;
  margin-bottom: 50px;

  text-align: center;

  ${screen.md} {
    text-align: left;
  }

  ${screen.lg} {
    margin-bottom: 80px;
  }

  ${screen.xxlg} {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Card = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  margin: 30px 0;

  ${screen.md} {
    align-items: flex-start;
  }
`;

export const CardSideBySide = styled(Card)`
  ${screen.md} {
    width: 50%;
  }
`;

export const CardGroup = styled(FlexRow)`
  justify-content: space-between;
  flex-direction: column;

  ${screen.md} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${screen.lg} {
  }

  ${screen.xxlg} {
  }
`;

export const Description = styled.h3`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0px;
  font-weight: bold;
  font-size: 19px;
  line-height: 30px;

  ${screen.md} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.lg} {
    font-size: 28px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const Text = styled.p`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-primary')});
  margin: 0;
  font-size: 19px;
  line-height: 30px;

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const Picture = styled.picture`
  box-shadow: ${getToken('elevation-2')};
  border-radius: 4px;

  width: 392px;

  img,
  source {
    width: 100%;
    height: auto;
  }

  ${screen.md} {
    width: 292px;
  }

  ${screen.lg} {
    width: 540px;
  }

  ${screen.xxlg} {
    width: 655px;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 28px 0;
`;
